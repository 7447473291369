import React, { useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { AnimatePresence, motion, useSpring } from "framer-motion"
import { wrap } from "@popmotion/popcorn"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DarkLeft from "assets/images/blackleft.png"
import DarkRight from "assets/images/blackright.png"
import LightLeft from "assets/images/whiteleft.png"
import LightRight from "assets/images/whiteright.png"

const { h2: H2, h3: H3 } = Styled
const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: direction => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const IndexPage = () => {
  const [isSliding, setIsSliding] = useState(true)
  const [[page, direction], setPage] = useState([0, 0])

  const {
    allPrismicProject: { edges },
  } = useStaticQuery(graphql`
    query FeaturedProjectsQuery {
      allPrismicProject(
        filter: { data: { featured: { eq: true } } }
        sort: { fields: data___sort_order }
      ) {
        edges {
          node {
            uid
            data {
              cover_image {
                alt
                fluid(maxWidth: 1780) {
                  ...GatsbyPrismicImageFluid
                }
                thumbnails {
                  mobile {
                    alt
                    fluid(maxWidth: 414) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
              }
              tags {
                text
              }
              title {
                text
              }
              dark_mode
              background_color
            }
          }
        }
      }
    }
  `)

  const projectIndex = wrap(0, edges.length, page)
  function paginate(newDirection) {
    setPage([page + newDirection, newDirection])
  }
  const colors = edges.map(({ node }) => node.data.background_color || "#fff")
  const { data } = edges[projectIndex].node

  useEffect(() => {
    const root = document.documentElement
    const { background_color, dark_mode } = data
    root.style.setProperty("--color-mode", dark_mode ? "dark" : "light")
    root.style.setProperty("--text", dark_mode ? `white` : `black`)
    root.style.setProperty("--background", background_color)
  }, [data])

  useEffect(() => {
    if (isSliding) {
      const timer = setTimeout(() => {
        const newDirection = direction || 1
        setPage(([page]) => [page + newDirection, newDirection])
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [isSliding, page, direction])

  function tags(string) {
    return string
      .split(",")
      .reduce((acc, current) => acc + `#${current.trim()} `, "")
  }

  function handlePrevious() {
    paginate(-1)
  }

  function handleNext() {
    paginate(1)
  }

  return (
    <Layout overlayBg={colors[projectIndex]} setIsSliding={setIsSliding}>
      <SEO />
      <Container>
        <AnimatePresence initial={false} custom={direction}>
          <FeaturedProject
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 200 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x)

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1)
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1)
              }
            }}
          >
            <DesktopImage
              alt={data.cover_image.alt}
              fluid={data.cover_image.fluid}
            />
            <HandheldImage
              alt={data.cover_image.thumbnails.mobile.alt}
              fluid={data.cover_image.thumbnails.mobile.fluid}
            />
            <ProjectFooter>
              <H2>{data.title.text}</H2>
              <H3>{tags(data.tags.text)}</H3>
            </ProjectFooter>
          </FeaturedProject>
        </AnimatePresence>
        <ClickNav>
          <Previous onClick={handlePrevious} />
          <Next onClick={handleNext} />
        </ClickNav>
      </Container>
    </Layout>
  )
}

const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

export default IndexPage

const Container = props => (
  <motion.div
    {...props}
    sx={{
      position: `fixed`,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: `var(--background)`,
      transition: `background 0.5s ease`,
      overflow: `hidden`,
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
    }}
  />
)

const FeaturedProject = props => (
  <motion.section
    {...props}
    sx={{
      position: `absolute`,
      display: `flex`,
      flexDirection: `column`,
      height: `100%`,
      minWidth: `100vw`,
      px: [4, 5],
      pt: 5,
    }}
  />
)

const ClickNav = props => (
  <div
    {...props}
    sx={{
      position: `absolute`,
      top: 5,
      bottom: 5,
      left: [4, 5],
      right: [4, 5],
      display: [`none`, `none`, `flex`],
    }}
  />
)

const Previous = ({ isDarkMode, ...rest }) => (
  <div
    {...rest}
    sx={{
      flex: 1,
      display: [`none`, `none`, `unset`],
      "&:hover": {
        cursor: `url("${isDarkMode ? LightLeft : DarkLeft}"), auto`,
      },
    }}
  />
)

const Next = ({ isDarkMode, ...rest }) => (
  <div
    {...rest}
    sx={{
      flex: 1,
      display: [`none`, `none`, `unset`],
      "&:hover": {
        cursor: `url("${isDarkMode ? LightRight : DarkRight}"), auto`,
      },
    }}
  />
)

const ProjectFooter = props => (
  <footer
    {...props}
    sx={{
      height: 2,
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `center`,
    }}
  />
)

const HandheldImage = props => (
  <Image
    {...props}
    sx={{
      flex: 1,
      display: [`unset`, `none`, `none`],
    }}
  />
)

const DesktopImage = props => (
  <Image
    {...props}
    sx={{
      flex: 1,
      display: [`none`, `unset`, `unset`],
    }}
  />
)
